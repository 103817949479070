import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo_gameit from "../Media/Documentation/assets/img/logo_gameit.png";
import { RxAvatar } from "react-icons/rx";
// import './nav.css'

const Header = (data) => {

  //To go on other page
  const navigate=useNavigate();

  //State to store search results
  const[searchList,setSearchList]=useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 

  const [mobiledropdown,setMobileDropdown]=useState(false)
  //Method to show entered text
  const handleSearch=(search)=>{
    // console.log("search is ",search);
    // console.log("data is ",data);
    
    let arr=[];
    data.data.forEach(e=>{
      e.game.forEach(f=>{
        // console.log("f ",f.name);

        if(search!=='' && search!==' ' && 
          (f.name.toLowerCase().startsWith(search) || f.name.startsWith(search)))
        {
          // console.log(f.name);
          arr.push(f.name);
        }
      })
    })
    // console.log("arry is ",arr);
    setSearchList(arr);
  }

  //Method to play that search game by its name
  const handleSearchPlay=(name)=>{
    // console.log("game name is ",name);
    
    //Get Selected Game
    data.data.forEach(e=>{
      e.game.forEach(f=>{
        if(f.name===name)
        {
          // console.log(f.game);
          window.location.href=f.game;
        }
      })
    })

  }

  const num=localStorage.getItem('msisdn')

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen); 
  const toggleDropdownMobile = () => setMobileDropdown(!mobiledropdown); 


   const logoutUser=()=>{
     localStorage.clear('msisdn')
     localStorage.clear('password')
     localStorage.clear('status')
     navigate('/login')
   }

  return (
    <header>
      <div id="sticky-header" className="main-header">
        <div className="container-fluid container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="main-menu">
                <nav>
                  <div className="logo">
                    <span style={{cursor:"pointer"}} onClick={()=>{
                      navigate("/");
                    }}>
                      <img
                        src={logo_gameit}
                        //   src="../Documentation/assets/img/logo_gameit.png"
                        alt="Logo"
                      />
                    </span>
                  </div>
                  <div
                    id="mobile-menu"
                    className="navbar-wrap d-none d-lg-flex"
                  >
                    <ul>
                      <li className="show">
                        <a href="#Sports">SPORTIFS</a>
                      </li>
                      <li>
                        <a href="#Funny">Funny</a>
                      </li>
                      <li>
                        <a href="#Action">D'ACTION</a>
                      </li>
                      <li>
                        <a href="#Adventure">D'AVENTURE</a>
                      </li>
                      <li>
                        <a href="#Racing">Racing</a>
                      </li>
                      <li>
                        <a href="#Kids">Kids</a>
                      </li>
                      <li>
                        <a href="#Arcade">ARCADE</a>
                      </li>
                      <li>
                        <a href="#Puzzle">PUZZLE</a>
                      </li>
                      </ul>
                  <div className="mt-[30px] relative" style={{display: 'block'}}>

                  <button id="dropdownUserAvatarButton" data-dropdown-toggle="dropdownAvatar" 
                  class="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4
                   focus:ring-gray-300 dark:focus:ring-gray-600" type="button"
                   onClick={toggleDropdown} >
                  <span class="sr-only">Open user menu</span>
                  {/* <img class="w-8 h-8 rounded-full" src={<RxAvatar/>} /> */}
                  <RxAvatar className="w-8 h-8" color='#FFE900'/>
                  </button>

                  <div id="dropdownAvatar"
                  className={`z-10 ${isDropdownOpen ? 'block' : 'hidden'} absolute right-[-50px] mt-2 bg-[#1E1227] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                  class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-50 dark:bg-gray-700 dark:divide-gray-600">
                      <div class="px-3 py-3 text-sm text-gray-900 dark:text-white">
                        <div className="text-gray-200">User</div>
                        <div class="font-medium truncate text-gray-200 mb-[-20px]">{num}</div>
                     <hr className="w-full"></hr>
                        <div className="text-gray-200 mt-[-20px] border-gray-200">Support Mail:</div>

                      <div class="lowercase text-gray-200 hover:text-[#FFE900] hover:cursor-pointer
                       dark:hover:bg-gray-600 dark:hover:text-white"
                       onClick={() => window.location.href = 'mailto:support@kncee.com'}>support@kncee.com</div> 
                      </div>
                      <div class="py-2">
                        <div class="block px-4  text-sm text-gray-200 hover:cursor-pointer hover:text-[#FFE900] dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={logoutUser}>Sign out</div>
                      </div>
                  </div>
                  </div>

                  
           
                  </div>
                  <div className="header-action">
                    <ul>
                      <li className="header-search">
                        <a
                          data-toggle="modal"
                          data-target="#search-modal"
                          href="/"
                        >
                          <i className="fas fa-search "></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="relative md:hidden"  id="avatarDropdownMobile">
  <button id="dropdownUserAvatarButtonMobile" data-dropdown-toggle="dropdownAvatarMobile" class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" 
  type="button" onClick={toggleDropdownMobile}>
    <span class="sr-only">Open user menu</span>
    <RxAvatar className="w-8 h-8" color='#FFE900'/>
  </button>

  <div id="dropdownAvatarMobile" className={`z-10 ${mobiledropdown ? 'block' : 'hidden'}  absolute right-[-20px] mt-2 bg-[#1E1227] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}>

    <div class="px-3 py-3 text-sm text-gray-900 dark:text-white">
      <div className="text-gray-200">User</div>
      <div class="font-medium truncate text-gray-200 mb-[-20px]">{num}</div>
      <hr className="w-full"></hr>
      <div className="text-gray-200 mt-[-20px] border-gray-200">Support Mail:</div>
      <div class="lowercase text-gray-200 hover:text-[#FFE900] hover:cursor-pointer dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => window.location.href = 'mailto:support@kncee.com'}>support@kncee.com</div> 
    </div>
    <div class="py-2">
      <div class="block px-4 text-sm hover:cursor-pointer text-gray-200 hover:text-[#FFE900] dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={logoutUser}>Sign out</div>
    </div>
  </div>
</div>

                </nav>
              </div>
              <div className="mobile-menu"></div>
            </div>
            {/* <!-- Modal Search --> */}
            <div
              className="modal fade"
              id="search-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." onChange={(e)=>{
                      handleSearch(e.target.value);
                    }}/>
                    <button>
                      <i className="fa fa-search"></i>
                    </button>
                    <div className="search-main-div">
                    {searchList.length>0 && searchList.map((val,key)=>{
                      // console.log("v ",val);
                      return(
                          <div key={key} className="search-div"><span className="search-span"
                          style={{cursor:"pointer"}} 
                          onClick={()=>{
                            handleSearchPlay(val);
                          }}>{val}</span></div>
                      )
                    })}
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;