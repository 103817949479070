import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";
import Login from "../Pages/Login";
import UserCheck from '../Pages/Usercheck'; 
import NewLogin from "../Pages/NewLogin";

const Routing = () => {
  const status=localStorage.getItem('status')

    return (
        <BrowserRouter>
        
            <Routes>
                <Route path="/" exact element={<Home />  } />
                {/* <Route path="/login" element={<NewLogin />} /> */}
                <Route path="/seeAll" exact element={<SeeAll />} />
                <Route path="*" exact element={<PageNotFound />} />
                <Route path="/error" exact element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Routing;
